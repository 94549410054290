import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import '../../App.css'
import InputBase, { inputBaseClasses } from '@mui/material/InputBase'
import InputLabel, { inputLabelClasses } from '@mui/material/InputLabel'
import TextField, { textFieldClasses } from '@mui/material/TextField'
import { FormControl } from '@mui/material'
import GVFormHelperText from '../gov-br/form-helper-text'
import { ThemeContext } from '../../context/theme-context'
import { styled } from '@mui/styles'
import GVFeedback from '../feedback'

const GVBRInputLabel = styled(InputLabel)(({ theme }) => {
  return ({
    [`&.${inputLabelClasses.root}`]: {
    color: theme.palette.mode === 'dark' ? 'var(--color-dark)' : 'var(--color)',
    fontSize: '1rem',
    fontWeight: 'var(--font-weight-semi-bold)',
    lineHeight: 'var(--font-line-height-medium)',
    marginBottom: 'var(--spacing-scale-half)',
    },
    [`&.${inputLabelClasses.focused}`]: {
      color: theme.palette.mode === 'dark' ? 'var(--color-dark)!important' : 'var(--color)!important',
    }
  })
})

const GVBRInputBase = styled(InputBase)(({ theme }) => {
  return ({
  '--input-button-margin': 'var(--spacing-scale-half)',
  '--input-padding': '0 var(--spacing-scale-2x)',
  '--input-padding-button': 'var(--spacing-scale-5x)',
  '--input-padding-icon': 'var(--spacing-scale-5x)',
  '--input-size': 'var(--input-medium)',
  '--input-small': '32px',
  '--input-medium': '40px',
  '--input-large': '48px',
  '--input-highlight': '56px',
  '--color': theme.palette.mode === 'dark' ? 'var(--color-dark)' : 'var(--color-light)',
  '--color-rgb': theme.palette.mode === 'dark' ? 'var(--color-dark-rgb)' : 'var(--color-light-rgb)',
  '--text-color': theme.palette.mode === 'dark' ? 'var(--color-dark)' : 'var(--color-light)',
  '--interactive': theme.palette.mode === 'dark' ? 'var(--interactive-dark)' : 'var(--interactive-light)',
  '--interactive-rgb': theme.palette.mode === 'dark' ? 'var(--interactive-dark-rgb)' : 'var(--interactive-light-rgb)',
  '--visited': theme.palette.mode === 'dark' ? 'var(--visited-dark)' : 'var(--visited-light)',
  '--hover': theme.palette.mode === 'dark' ? 'var(--hover-dark)' : 'var(--hover-light)',
  '--pressed': theme.palette.mode === 'dark' ? 'var(--pressed-dark)' : 'var(--pressed-light)',
  '--focus-color': theme.palette.mode === 'dark' ? 'var(--focus-color-dark)' : 'var(--focus-color-light)',
  '--focus': theme.palette.mode === 'dark' ? 'var(--focus-color-dark)' : 'var(--focus-color-light)',
  color: 'var(--color)',
  position: 'relative',

  'label + &': {
    marginRight: 'var(--spacing-scale-baseh)',
    marginTop: 'calc(var(--input-size) * 0.5 - var(--spacing-scale-half))'
  },
  [`& .${inputBaseClasses.input}`]: {
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--pure-0)' : 'var(--background-light)',
    borderColor: 'var(--border-color-alternative)',
    borderRadius: 'var(--surface-rounder-sm)',
    borderStyle: 'var(--border-style)',
    borderWidth: 'var(--border-width)',
    color: 'var(--color-light)',
    display: 'block',
    fontSize: 'var(--font-size-scale-up-01)',
    fontWeight: 'var(--font-weight-medium)',
    height: 'var(--input-size)',
    marginTop: 'var(--spacing-scale-half)',
    paddingBottom: '0',
    paddingLeft: 'var(--spacing-scale-base)',
    paddingRight: 'var(--spacing-scale-base)',
    paddingTop: '0',
   
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'var(--pure-0)' : 'var(--gray-2)',
    }
    
  },
  [`&.${inputBaseClasses.error} input`]: {
    borderColor: 'var(--danger)'
  },
  [`&.${inputBaseClasses.focused} input`]: {
    borderColor: 'var(--focus) !important',
    boxShadow: '0 0 0 var(--surface-width-md) var(--focus)',
    outline: 'none'
  },
})})

const GVInputLabel = ({ children, ...props }) => {
  return (<GVBRInputLabel {...props} >
    {children}
  </GVBRInputLabel>)
}

const GVInputBase = ({ ...props }) => {
  return (<GVBRInputBase {...props} />)
}

function CustomTextField({ autoComplete, name, label, placeholder, required, fullWidth, value, onChange, onCut, onCopy, onPaste, error, helperText, inputProps, type = "text" }) {
  
  const notEventChange = (e) => {
    e.preventDefault()
  }

  if (type === "email") {
      return (<FormControl error={error} fullWidth={ fullWidth ? { width: '100%' } : {} } variant="standard">
      <GVBRInputLabel shrink htmlFor={name}>{label}</GVBRInputLabel>
      <GVBRInputBase
     
        autoComplete="off"
        aria-label={name}
        id={name}
        name={name}
        label={label}
        placeholder={placeholder}
        fullWidth={fullWidth}
        value={value}
        onChange={onChange}
        onCut={onCut}
        onCopy={onCopy}
        onPaste={onPaste}
        error={error}
        helperText={helperText}
        inputProps={inputProps} 
        sx={{
          [`& .${inputBaseClasses.input}`]: {
            textTransform: 'lowercase'
          },
        }}
        
      
        />
      {required && error && (
        <GVFeedback danger>{helperText}</GVFeedback>
      )}
    </FormControl>)
  } else {
    return (
      <FormControl error={error} fullWidth={ fullWidth ? { width: '100%' } : {} } variant="standard">
        <GVBRInputLabel shrink htmlFor={name}>{label}</GVBRInputLabel>
        <GVBRInputBase
          aria-label={name}
          id={name}
          name={name}
          label={label}
          placeholder={placeholder}
          fullWidth={fullWidth}
          value={value}
          onChange={onChange}
          error={error}
          helperText={helperText}
          inputProps={inputProps}
          sx={{
          
            [`& .${inputBaseClasses.input}`]: {
              textTransform: type == "confirmEmail" ? 'lowercase' :'none',
            },
          }}
          
          />
        {required && error && (
          <GVFeedback danger>{helperText}</GVFeedback>
        )}
      </FormControl>
    )
  }
}

CustomTextField.prototypes = {
  autoComplete: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  inputProps: PropTypes.object,
  type: PropTypes.string,
}

export default CustomTextField;