import React, { createContext, useState } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material';
import typography from '../styles/typography';
import typographyDark from '../styles/typography-dark';

import '../App.css'
import { darkPalette, palette } from '../styles/palette';

const white = '#FFFFFF';
const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light');

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  let restPalette = theme === 'light' ? palette : darkPalette

  const appTheme = createTheme({
    palette: {
      mode: theme,
      mainprimary: {
        contrastText: white,
        dark: '#3f51b4',
        main: '#3f51b4',
        light: '#3f51b4'
      },
      mainsecondary: {
        contrastText: white,
        dark: '#277afe',
        main: '#277afe',
        light: '#277afe'
      },
    },
    typography: theme === 'light' ? typography : typographyDark,
    breakpoints: {
        xs: 0,
        sm: 576,
        md: 992,
        lg: 1280,
        xl: 1600
    }
  });

  return (
    <ThemeContext.Provider value={{ toggleTheme, theme }}>
      <MuiThemeProvider theme={appTheme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeProvider, ThemeContext };