import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import ability from './config/ability'
import { AbilityContext } from './config/ability-context'
import { PersistGate } from 'redux-persist/integration/react';

ReactDOM.render(
    <AbilityContext.Provider value={ability}>               
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <App />
                </PersistGate>
            </Provider>        
    </AbilityContext.Provider>,        
document.getElementById('root'));

