import FormHelperText, { formHelperTextClasses } from '@mui/material/FormHelperText'
import { styled } from '@mui/styles'
import React from 'react'
import PropTypes from 'prop-types'
import CancelIcon from '@mui/icons-material/Cancel';import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import '../../../App.css'

const getIcon = (danger, success, info, warning) => {
  if (danger) {
    return <CancelIcon fontSize="inherit" />;
  } else if (success) {
    return <CheckCircleIcon fontSize="inherit" />;
  } else if (info) {
    return <InfoIcon fontSize="inherit" />;
  } else if (warning) {
    return <WarningIcon fontSize="inherit" />;
  } else {
    return null;
  }
};

const GVBRFormHelperText = styled(FormHelperText)(({ theme }) => ({
  [`&.${formHelperTextClasses.root} `]: {
    '--message-background': 'var(--background)',
    '--message-color-icon': 'var(--color)',
    '--feedback-background': 'var(--background)',
    '--message-text': 'var(--color)',
    alignItems: 'center',
    display: 'inline-flex',
    fontStyle: 'italic',
    fontWeight: 'var(--font-weight-medium)',
    marginBottom: '0',
    padding: 'var(--spacing-scale-half)',
  },
  [`&.${formHelperTextClasses.error}`]: {
    color: '#fff!important',
    background: '#E52207',
  },
}))

const GVFormHelperText = ({ danger, success, info, warning, customIcon, children, ...rest }) => {
  const icon = getIcon(danger, success, info, warning, customIcon);

  return (
    <GVBRFormHelperText {...rest}>
      {icon && React.cloneElement(icon, { fontSize: 'inherit', marginRight: 'var(--spacing-scale-half)' })}
      {children}
    </GVBRFormHelperText>
  );
};

GVFormHelperText.propTypes = {
  danger: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  customIcon: PropTypes.element,
  children: PropTypes.node.isRequired,
};

export default GVFormHelperText;