import axios from 'axios';
import * as userSessionActions from '../redux/actions/user-session';
import { useHistory } from "react-router-dom";
import { Mexx2DOConsole } from '../utils/mexxTalkConsole';

Mexx2DOConsole.log("dev env")

let saasError = false
try {
  const urlSaaS = process.env.REACT_APP_API+"/hirerconfig/geturl/"+window.location.host+"/8";
  Mexx2DOConsole.log(urlSaaS)
  var xhttp = new XMLHttpRequest();
  xhttp.open("GET", urlSaaS, false);
  xhttp.send();
} catch(e) {
  if(e.name === "NetworkError") {
    Mexx2DOConsole.log("entrou NetworkError")
    if(e.message.indexOf("Failed to execute 'send' on 'XMLHttpRequest'") !== -1) {
      saasError = true;
      Mexx2DOConsole.log("entrou Failed 'send' 'XMLHttpRequest'")
    }else if(e.message.indexOf("A network error occurred.") !== -1) {
      saasError = true;
      Mexx2DOConsole.log("entrou em A network error occurred.")
    }else {
      Mexx2DOConsole.log("Outro erro")
      Mexx2DOConsole.log(e.name)
      Mexx2DOConsole.log(e.message)
    }
  } else {
    Mexx2DOConsole.log(e)
    Mexx2DOConsole.log(e.name)
    Mexx2DOConsole.log(e.message)
  }
}

let Api = {}

let baseURL = null
let baseURLBack = null

if(!saasError) {

  Mexx2DOConsole.log(xhttp);

  var baseUrlResponse = JSON.parse(xhttp.responseText);
  if(baseUrlResponse && baseUrlResponse.data && baseUrlResponse.data.urlapicubecalledservice) {
    baseURL = "https://"+baseUrlResponse.data.urlapicubecalledservice+"/api"; 
    baseURLBack = "https://"+baseUrlResponse.data.urlapicubecalledservice+"/"; 
    const urlfront = window.location.host;

    Api = axios.create({
      baseURL: "https://"+baseUrlResponse.data.urlapicubecalledservice+"/api",
    });

    Api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    Api.defaults.headers.post['Accept'] = 'application/json';
    Api.defaults.headers.post['Content-Type'] = 'application/json';

    let token = localStorage.getItem('accesstoken');

    if (token == null) {
    }
    Api.applicationName = function () {
      return "INTELLIGENT SERVICE ATENDENT";
    }

    Api.apiurl = function () {
      return baseURL;
    }
    Api.apiurlbase = function () {
      return baseURLBack;
    }

    Api.fronturl = function () {
      return urlfront;
    }

    Api.getProductsAvaliable = async function  (hirerid) {
      var ret = [];
      var Hirer = {
        Id : hirerid
      };
      var headers = {
        'Content-Type': 'application/json',
      };
      Mexx2DOConsole.log('getProductsAvaliable');


      await this.post("/users/avaliable/products", Hirer, {
        headers: headers
      })
        .then(async result => {
          if (result.data.success) {
            //alert(JSON.stringify(result));
            if(result && result.data && result.data.success){
              if(result.data.data){
                ret = result.data.data;
              }
            }        
          
          }
        })
        .catch(err => {
          if(err.response && err.response.data && err.response.data.errors){
          }
          else{
          }
        });

      return ret;
    }
    Api.VertexIsAvaliable = async function (){
      var result = false;
      var data = await this.getProductsAvaliable();
      if(data.constructor === Array && data.length > 0){
        data.forEach(element => {
          if(element.id == 3){
            result=true;
          }
        });
      }
      return result;
    }

    Api.logoff = function () {
      userSessionActions.removeUserSession();
      Api.get("/users/removeClaims")
        .then(result => {
          document.location.href = '/?sessão expirada';
        })
        .catch(err => {
          document.location.href = '/?sessão expirada';
      });
      
    }

    Api.kickoff = function (result){
      if((result.response && result.response.data && result.response.data.errors && result.response.data.errors[0])){
    
        if((result.response.data.errors[0].indexOf("invalid_token") > -1)){
      
          userSessionActions.removeUserSession();
          document.location.href='/?sessão expirada';
        }

        if((result.response.data.errors[0].indexOf("ContractInvalid") > -1)){
      
          document.location.href='/pt/my-plan?message';
        }
      } 
      if((result.data && result.data.response && result.data.response.data && result.data.response.data.errors && result.data.response.data.errors[0]) ){
    
        if( (result.data.response.data.errors[0].indexOf("invalid_token") > -1)){
        
          userSessionActions.removeUserSession();
          document.location.href='/?sessão expirada';
        }

        if( (result.data.response.data.errors[0].indexOf("ContractInvalid") > -1)){
        
          document.location.href='/pt/my-plan?message';
        }
      }
      
      if(result.errors && result.errors[0] ){
          if(result.errors[0] === "Error: invalid_token")
          {
          
            userSessionActions.removeUserSession();
            document.location.href='/?sessão expirada';
          }

          if((result.response.data.errors[0].indexOf("invalid_token") > -1)){
      
            userSessionActions.removeUserSession();
            document.location.href='/?sessão expirada';
          }

          if(result.errors[0] === "Error: ContractInvalid")
          {
          
            document.location.href='/pt/my-plan?message';
          }

          if((result.response.data.errors[0].indexOf("ContractInvalid") > -1)){
      
            document.location.href='/pt/my-plan?message';
          }
      }
      
    
      if(result.data && result.data.errors && result.data.errors[0])
      {
        if((result.data.errors[0] === "Error: invalid_token")){
          userSessionActions.removeUserSession();
          document.location.href='/?sessão expirada';
        }
        
        if((result.data.errors[0] === "Error: ContractInvalid")){
          document.location.href='/pt/my-plan?message';
        }
      }
    

    }

    Api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }else {
    Api = null
    baseURL = null
    baseURLBack = null
  }
} else {
  Api = null
  baseURL = null
  baseURLBack = null
}

export { baseURL, baseURLBack }
export default Api;
