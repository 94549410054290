import React from 'react'
import { Paper } from "@mui/material";
import { styled } from "@mui/styles";

const GVPaper = styled(Paper)(({ theme }) => ({
  '--card-padding': 'var(--spacing-scale-2x)',
  '--card-height-fixed': '250px',
  background: 'var(--background)',
  boxShadow: 'var(--surface-shadow-sm)',
  color: 'var(--color)',
  marginBottom: 'var(--spacing-scale-2x)',
}))

export default GVPaper;