import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText } from '@mui/material';

export default function BasicSelect(props) {

  const handleChange = (e) => {
    props.handleChange(e.target.value)
  }

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth error={props.error}>
        <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.value}
          label={props.label}
          onChange={handleChange}
        >
          <MenuItem value={0}>-- Selecione --</MenuItem>
          {props.list && props.list.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
        </Select>
        { props.error && props.error === true && <FormHelperText>{props.errorText}</FormHelperText> }
      </FormControl>
    </Box>
  );
}