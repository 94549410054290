import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CssBaseline, Container, CircularProgress, Grid, Typography, TextField, Button, Paper, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import CustomizedSnackbars from '../../components/material-snackbars'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import Logo from '../../components/logo/logo'
import moment from 'moment'
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole'
import Header from '../../components/header'
import Footer from '../../components/footer'
import LogoGovBr from '../../components/logo/logo-gov-br'
import './styles.css'
import FormFooter from '../../components/form-footer'


class FormSendSuccess extends Component {
  constructor(props) {
    super(props)

    let { location } = this.props

    this.state = {
      calledId: location && location.state && location.state.calledId ? location.state.calledId : 0,
      createdAt: location && location.state && location.state.createdAt ? moment(location.state.createdAt).format('DD/MM/YYYY') : '',
      name: location && location.state && location.state.name ? location.state.name : '',
      email: location && location.state && location.state.email ? location.state.email : '',
      document: location && location.state && location.state.document ? location.state.document : '',
      ofieldsText: location && location.state && location.state.ofieldsText ? location.state.ofieldsText : '',
      backRoute: location && location.state && location.state.backRoute ? location.state.backRoute : '',
      footerMessage: location && location.state && location.state.footerMessage ? location.state.footerMessage : '',
      loading: false,
      notificationVariant: 'success',
      notificationMessage: '',
      openNotification: false
    }

    Mexx2DOConsole.log("=== FormSendSuccess ===")
    Mexx2DOConsole.log(location && location.state && location.state.ofieldsText ? location.state.ofieldsText : '')

    this.closeNotification = this.closeNotification.bind(this)
  }

  

  closeNotification = _ => this.setState({ openNotification: false })

  convertHtmlOption = value => {
    
    let body = value
    let temp = document.createElement("div")
    temp.innerHTML = body
    let found = null
    for (let i in temp.children) {
        if (temp.children[i].innerHTML && temp.children[i].innerHTML.toString().trim() != '<br>' && temp.children[i].innerHTML.toString().trim().length > 0) {
            found += temp.children[i].innerHTML.toString()
        }
    }
    let sanitized = temp.textContent.trim() || temp.innerText.trim() || found

   
    return sanitized
}

  render() {
    const { classes, intl } = this.props;

    return (
      <Grid container spacing={0}>
        <Grid container spacing={0} style={{ backgroundColor: '#F8F8F8', padding: '3% 15%', minHeight: 'calc(100vh - 225px)' }}>
          <Grid container spacing={0}>
            <Grid item sm={12} md={12} lg={12} style={{ width: '100%', boxSizing: 'border-box' }} >
              <Paper elevation={3} style={{ padding: '4% 10%', width: '100%', boxSizing: 'border-box' }}>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={12} lg={12} style={{ marginBottom: 30 }}>
                    <Typography variant="h2" className={this.props.class} style={{ color: '#1351b4', margin: 0 }}>
                      Solicitação enviada com sucesso!
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                      Número do protocolo:&nbsp;
                    </Typography>
                    <Typography variant="body1" component="span" className={this.props.class}>
                      {this.state.calledId}
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                      Data de criação:&nbsp;
                    </Typography>
                    <Typography variant="body1" component="span" className={this.props.class}>
                      {this.state.createdAt}
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <div className="fontFamilyInnerHTML" dangerouslySetInnerHTML={{__html: this.state.ofieldsText}} />
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                      Nome:&nbsp;
                    </Typography>
                    <Typography variant="body1" component="span" className={this.props.class} style={{ wordWrap: 'break-word' }}>
                      {this.state.name}
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                      E-mail:&nbsp;
                    </Typography>
                    <Typography variant="body1" component="span" className={this.props.class}>
                      {this.state.email}
                    </Typography>
                  </Grid>
                  {document.length == 0 && <Grid item sm={12} md={12} lg={12}>
                    <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                      CPF:&nbsp;
                    </Typography>
                    <Typography variant="body1" component="span" className={this.props.class}>
                      {this.state.document}
                    </Typography>
                  </Grid>}
                  {(this.state.footerMessage != null && this.state.footerMessage != "") &&
                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                      <Paper elevation={3} >
                        <Grid container spacing={0}>
                          <Grid item xs={12} sm={2} md={2} lg={2} style={{ backgroundColor: '#1351b4' }}></Grid>
                          <Grid item xs={12} sm={10} md={10} lg={10} style={{ padding: '25px 25px 25px 20px' }}>
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Typography variant="p" className={this.props.class} style={{ marginBottom: 0 }}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.footerMessage }} />
                                  </Typography>
                                </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  }
                  <Grid item sm={12} md={12} lg={12} style={{ paddingTop: 40, display: 'flex', justifyContent: 'center' }}>
                    <Button
                      onClick={() => {this.props.history.push({ pathname: `/${this.props.match.params.lang}/${this.state.backRoute.length > 0 ? this.state.backRoute : ''}`})}}
                      variant="contained"
                      color="primary"
                      style={{ color: '#fff', padding: 14, textTransform: 'captalize!important' }} >
                      Enviar outra solicitação
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
          )}

          <CustomizedSnackbars
            variant={this.state.notificationVariant}
            message={this.state.notificationMessage}
            isOpen={this.state.openNotification}
            toClose={this.closeNotification} />
          </Grid>
        <Grid item sm={12} md={12} lg={12} >
          <FormFooter />
        </Grid>
      </Grid>
    )
  }
}

FormSendSuccess.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(FormSendSuccess)));