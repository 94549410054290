import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CssBaseline, Container, CircularProgress, Grid, Typography, TextField, Button, Paper, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import CustomizedSnackbars from '../../components/material-snackbars'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import Logo from '../../components/logo/logo'
import moment from 'moment'
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole'
import LogoGovBr from '../../components/logo/logo-gov-br'
import Footer from '../../components/gov-br/footer'
import Header from '../../components/gov-br/header'
import GVButton from '../../components/gov-br/button'
import GVPaper from '../../components/paper'

class AttendanceErrorBlacklistNew extends Component {
  constructor(props) {
    super(props)

    let { location } = this.props

    this.state = {
      name: location && location.state && location.state.name ? location.state.name : '',
      email: location && location.state && location.state.email ? location.state.email : '',
      document: location && location.state && location.state.document ? location.state.document : '',
      backRoute: location && location.state && location.state.backRoute ? location.state.backRoute : '',
      loading: false,
      notificationVariant: 'success',
      notificationMessage: '',
      openNotification: false
    }

    this.closeNotification = this.closeNotification.bind(this)
  }

  closeNotification = _ => this.setState({ openNotification: false })

  render() {
    const { classes, intl } = this.props;

    return (<div style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh'
     }}>
      <CssBaseline />
      <Header />
      <Container component="main" maxWidth="xl" sx={{
        marginTop: 'var(--spacing-scale-3x)!important',
        maxWidth: '100%!important',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
        }}>
        {/* Conteúdo principal */}
        <Grid container
          rowSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
          columnSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}>
            <Grid item xs={12} sm={12} md={4} lg={2} >
              <Grid container
                rowSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
                columnSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }} >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} />
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Typography variant="p" className={this.props.class} style={{ textAlign: 'right', marginRight: '5%', fontStyle: 'italic', fontWeight: 400 }} >
                    Atendimento ao usuário – Conta GOV.BR.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          <Grid item xs={12} sm={12} md={8} lg={9} style={{ width: '100%', boxSizing: 'border-box' }} >
            <GVPaper elevation={3} style={{ padding: '4% 5.6%', width: '100%', boxSizing: 'border-box', marginBottom: 30 }}>
              <Grid container rowSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
          columnSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 10 }}>
                  <Typography variant="body1" component="p" className={this.props.class} style={{ marginBottom: 0 }}>
                    Detectamos alterações de e-mail incomuns na conta gov.br do portador do CPF informado.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 10 }}>
                  <Typography variant="body1" component="p" className={this.props.class}  style={{ fontWeight: 'bold', marginBottom: 0 }}>
                    Para evitar fraudes, pedimos que registre sua solicitação em:
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 10 }}>
                  <Typography variant="body1" component="p" className={this.props.class} style={{ marginBottom: 0 }}>
                    <a href="https://www.gov.br/governodigital/pt-br/atendimento-gov.br/duvidas-nos-dados-cadastrais/nao-alterei-meus-dados-cadastrais">https://www.gov.br/governodigital/pt-br/atendimento-gov.br/duvidas-nos-dados-cadastrais/nao-alterei-meus-dados-cadastrais</a>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 30 }}>
                  <Typography variant="body1" component="p" className={this.props.class} style={{ marginBottom: 0 }}>
                    Equipe gov.br
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40, display: 'flex', justifyContent: 'center' }}>
                  <GVButton
                    onClick={() => {this.props.history.push({ pathname: `/${this.props.match.params.lang}/${this.state.backRoute.length > 0 ? this.state.backRoute : ''}`})}}
                    variant="contained"
                    style={{ padding: 14 }}
                    label="Enviar outra solicitação" >
                    Enviar outra solicitação
                  </GVButton>
                </Grid>
              </Grid>
            </GVPaper>
          </Grid>
        </Grid>
        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}

        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification} />
        </Container>
      <Footer />
    </div>)
  }
}

AttendanceErrorBlacklistNew.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(AttendanceErrorBlacklistNew)));