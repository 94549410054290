import { colors } from '@mui/material';
import "./colors/pure.css";
import './colors/default/blue-warm.css';
import "./colors/vivid/blue-warm-vivid.css";
import './colors/default/gray.css';
import "./colors/vivid/green-cool-vivid.css";
import './colors/vivid/yellow-vivid.css';
import './colors/vivid/red-vivid.css'

const black = '#000000';
const white = '#FFFFFF';

export const palette = {
  black,
  white,
  mainprimary: {
    contrastText: white,
    dark: '#3f51b4',
    main: '#3f51b4',
    light: '#3f51b4'
  },
  mainsecondary: {
    contrastText: white,
    dark: '#277afe',
    main: '#277afe',
    light: '#277afe'
  },
  primary: {
    contrastText: '#333333',
    dark: '#FFFFFF',
    main: '#FFFFFF',
    light: '#FFFFFF',
  },
  secondary: {
    contrastText: '#FFFFFF',
    dark: '#CCCCCC',
    main: '#CCCCCC',
    light: '#CCCCCC',
  },
  container: {
    contrastText: '#333333',
    dark: '#FFFFFF',
    main: '#FFFFFF',
    light: '#FFFFFF',
  },
  containerAlternative: {
    contrastText: '#FFFFFF',
    dark: '#CCCCCC',
    main: '#CCCCCC',
    light: '#CCCCCC',
  },
  reading: {
    contrastText: white,
    dark: '#333333',
    main: '#333333',
    light: '#333333',
  },
  interactionFeedback: {
    contrastText: white,
    dark: '#1351B4',
    main: '#1351B4',
    light: '#1351B4',
  },
  info: {
    contrastText: white,
    dark: '#155BCB',
    main: '#155BCB',
    light: '#155BCB',
  },
  infoAlternative: {
    contrastText: '#333333',
    dark: '#D4E5FF',
    main: '#D4E5FF',
    light: '#D4E5FF',
  },
  success: {
    contrastText: white,
    dark: '#168821',
    main: '#168821',
    light: '#168821',
  },
  successAlternative: {
    contrastText: '#333333',
    dark: '#E3F5E1',
    main: '#E3F5E1',
    light: '#E3F5E1',
  },
  warning: {
    contrastText: '#333333',
    dark: '#FFCD07',
    main: '#FFCD07',
    light: '#FFCD07',
  },
  warningAlternative: {
    contrastText: '#333333',
    dark: '#FFF5C2',
    main: '#FFF5C2',
    light: '#FFF5C2',
  },
  error: {
    contrastText: '#FFFFFF',
    dark: '#E52207',
    main: '#E52207',
    light: '#E52207',
  },
  errorAlternative: {
    contrastText: '#333333',
    dark: '#FDE0DB',
    main: '#FDE0DB',
    light: '#FDE0DB',
  }
};

export const darkPalette = {
  black,
  white,
  mainprimary: {
    contrastText: white,
    dark: '#3f51b4',
    main: '#3f51b4',
    light: '#3f51b4'
  },
  mainsecondary: {
    contrastText: white,
    dark: '#277afe',
    main: '#277afe',
    light: '#277afe'
  },
  container: {
    contrastText: white,
    dark: '#071D41',
    main: '#071D41',
    light: '#071D41',
  },
  containerAlternative: {
    contrastText: white,
    dark: '#0C326F',
    main: '#0C326F',
    light: '#0C326F',
  },
  reading: {
    contrastText: white,
    dark: '#FFFFFF',
    main: '#FFFFFF',
    light: '#FFFFFF',
  },
  interactionFeedback: {
    contrastText: white,
    dark: '#C5D4EB',
    main: '#C5D4EB',
    light: '#C5D4EB',
  },
  info: {
    contrastText: white,
    dark: '#155BCB',
    main: '#155BCB',
    light: '#155BCB',
  },
  infoAlternative: {
    contrastText: '#333333',
    dark: '#D4E5FF',
    main: '#D4E5FF',
    light: '#D4E5FF',
  },
  success: {
    contrastText: white,
    dark: '#168821',
    main: '#168821',
    light: '#168821',
  },
  successAlternative: {
    contrastText: '#333333',
    dark: '#E3F5E1',
    main: '#E3F5E1',
    light: '#E3F5E1',
  },
  warning: {
    contrastText: '#333333',
    dark: '#FFCD07',
    main: '#FFCD07',
    light: '#FFCD07',
  },
  warningAlternative: {
    contrastText: '#333333',
    dark: '#FFF5C2',
    main: '#FFF5C2',
    light: '#FFF5C2',
  },
  error: {
    contrastText: '#FFFFFF',
    dark: '#E52207',
    main: '#E52207',
    light: '#E52207',
  },
  errorAlternative: {
    contrastText: '#333333',
    dark: '#FDE0DB',
    main: '#FDE0DB',
    light: '#FDE0DB',
  }
};
