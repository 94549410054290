import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiInputLabel-root': {
    transform: 'translate(14px, 16px) scale(1)',
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(14px, -4px) scale(0.75)',
  },
  '& .MuiFormControl-root': {
    marginTop: '16px', // Ajuste o valor conforme necessário
  },
});

const CustomAutocomplete = ({ options, label, value, ...props }) => {
  const selectedOption = options.find(option => option.id === value);

  return (
    <StyledAutocomplete
      options={options}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue}
      renderInput={(params) => (
        <TextField {...params} label={label} />
      )}
      value={selectedOption || null}
      {...props}
    />
  );
};

export default CustomAutocomplete;