import { colors } from '@mui/material';
import "./colors/pure.css";
import './colors/default/blue-warm.css';
import "./colors/vivid/blue-warm-vivid.css";
import './colors/default/gray.css';
import "./colors/vivid/green-cool-vivid.css";
import './colors/vivid/yellow-vivid.css';
import './colors/vivid/red-vivid.css'

const black = 'var(--pure-100)';
const white = 'var(--pure-0)';

export const palette = {
  black,
  white,
  mainprimary: {
    contrastText: white,
    dark: '#3f51b4',
    main: '#3f51b4',
    light: '#3f51b4'
  },
  mainsecondary: {
    contrastText: white,
    dark: '#277afe',
    main: '#277afe',
    light: '#277afe'
  },
  primary: {
    contrastText: 'var(--gray-80)',
    dark: 'var(--pure-0)',
    main: 'var(--pure-0)',
    light: 'var(--pure-0)',
  },
  secondary: {
    contrastText: 'var(--pure-0)',
    dark: 'var(--gray-2)',
    main: 'var(--gray-2)',
    light: 'var(--gray-2)',
  },
  container: {
    contrastText: 'var(--gray-80)',
    dark: 'var(--pure-0)',
    main: 'var(--pure-0)',
    light: 'var(--pure-0)',
  },
  containerAlternative: {
    contrastText: 'var(--pure-0)',
    dark: 'var(--gray-2)',
    main: 'var(--gray-2)',
    light: 'var(--gray-2)',
  },
  reading: {
    contrastText: white,
    dark: 'var(--pure-0)',
    main: 'var(--pure-0)',
    light: 'var(--pure-0)',
  },
  interactionFeedback: {
    contrastText: white,
    dark: 'var(--blue-warm-vivid-70)',
    main: 'var(--blue-warm-vivid-70)',
    light: 'var(--blue-warm-vivid-70)',
  },
  info: {
    contrastText: white,
    dark: 'var(--blue-warm-vivid-60)',
    main: 'var(--blue-warm-vivid-60)',
    light: 'var(--blue-warm-vivid-60)',
  },
  infoAlternative: {
    contrastText: 'var(--gray-80)',
    dark: 'var(--blue-warm-vivid-10)',
    main: 'var(--blue-warm-vivid-10)',
    light: 'var(--blue-warm-vivid-10)',
  },
  success: {
    contrastText: white,
    dark: 'var(--green-cool-vivid-50)',
    main: 'var(--green-cool-vivid-50)',
    light: 'var(--green-cool-vivid-50)',
  },
  successAlternative: {
    contrastText: 'var(--gray-80)',
    dark: 'var(--green-cool-vivid-5)',
    main: 'var(--green-cool-vivid-5)',
    light: 'var(--green-cool-vivid-5)',
  },
  warning: {
    contrastText: 'var(--gray-80)',
    dark: 'var(--yellow-vivid-20)',
    main: 'var(--yellow-vivid-20)',
    light: 'var(--yellow-vivid-20)',
  },
  warningAlternative: {
    contrastText: 'var(--gray-80)',
    dark: 'var(--yellow-vivid-5)',
    main: 'var(--yellow-vivid-5)',
    light: 'var(--yellow-vivid-5)',
  },
  error: {
    contrastText: 'var(--pure-0)',
    dark: 'var(--red-vivid-50)',
    main: 'var(--red-vivid-50)',
    light: 'var(--red-vivid-50)',
  },
  errorAlternative: {
    contrastText: 'var(--gray-80)',
    dark: 'var(--red-vivid-10)',
    main: 'var(--red-vivid-10)',
    light: 'var(--red-vivid-10)',
  }
};

export const darkPalette = {
  black,
  white,
  container: {
    contrastText: white,
    dark: 'var(--blue-warm-vivid-90)',
    main: 'var(--blue-warm-vivid-90)',
    light: 'var(--blue-warm-vivid-90)',
  },
  containerAlternative: {
    contrastText: white,
    dark: 'var(--blue-warm-vivid-80)',
    main: 'var(--blue-warm-vivid-80)',
    light: 'var(--blue-warm-vivid-80)',
  },
  reading: {
    contrastText: white,
    dark: 'var(--pure-0)',
    main: 'var(--pure-0)',
    light: 'var(--pure-0)',
  },
  interactionFeedback: {
    contrastText: white,
    dark: 'var(--blue-warm-20)',
    main: 'var(--blue-warm-20)',
    light: 'var(--blue-warm-20)',
  },
  info: {
    contrastText: white,
    dark: 'var(--blue-warm-vivid-60)',
    main: 'var(--blue-warm-vivid-60)',
    light: 'var(--blue-warm-vivid-60)',
  },
  infoAlternative: {
    contrastText: 'var(--gray-80)',
    dark: 'var(--blue-warm-vivid-10)',
    main: 'var(--blue-warm-vivid-10)',
    light: 'var(--blue-warm-vivid-10)',
  },
  success: {
    contrastText: white,
    dark: 'var(--green-cool-vivid-50)',
    main: 'var(--green-cool-vivid-50)',
    light: 'var(--green-cool-vivid-50)',
  },
  successAlternative: {
    contrastText: 'var(--gray-80)',
    dark: 'var(--green-cool-vivid-5)',
    main: 'var(--green-cool-vivid-5)',
    light: 'var(--green-cool-vivid-5)',
  },
  warning: {
    contrastText: 'var(--gray-80)',
    dark: 'var(--yellow-vivid-20)',
    main: 'var(--yellow-vivid-20)',
    light: 'var(--yellow-vivid-20)',
  },
  warningAlternative: {
    contrastText: 'var(--gray-80)',
    dark: 'var(--yellow-vivid-5)',
    main: 'var(--yellow-vivid-5)',
    light: 'var(--yellow-vivid-5)',
  },
  error: {
    contrastText: 'var(--pure-0)',
    dark: 'var(--red-vivid-50)',
    main: 'var(--red-vivid-50)',
    light: 'var(--red-vivid-50)',
  },
  errorAlternative: {
    contrastText: 'var(--gray-80)',
    dark: 'var(--red-vivid-10)',
    main: 'var(--red-vivid-10)',
    light: 'var(--red-vivid-10)',
  }
};
