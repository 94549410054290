export function addUserSession(user) {
    return {
        type: 'ADD_USER',
        user,
    }
}

export function removeUserSession() {
    localStorage.removeItem('accesstoken');
    return {
        type: 'REMOVE_USER'
    }
}

