import * as React from 'react';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { FormControl, IconButton, InputAdornment } from '@mui/material';
import { styled } from '@mui/styles';
import GVFeedback from '../feedback';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../../App.css'
import './styles.css'
import GVIconButton from '../icon-button';
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole';

const options = [{ id: 1, name:'Option 1' }, { id: 2, name:'Option 2' }];

const GVBRAutocompleteLabel = styled('label')(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'var(--color-dark)' : 'var(--color)',
  fontSize: '0.78rem',
  fontWeight: 'var(--font-weight-semi-bold)',
  lineHeight: 'var(--font-line-height-medium)',
  marginBottom: 'var(--spacing-scale-half)',
  '&:focus': {
    color: theme.palette.mode === 'dark' ? 'var(--color-dark)!important' : 'var(--color)!important',
  }
}))

const GVBRAutocomplete = styled(Autocomplete)(({ theme }) => {
  return ({
    '--input-button-margin': 'var(--spacing-scale-half)',
    '--input-padding': '0 var(--spacing-scale-2x)',
    '--input-padding-button': 'var(--spacing-scale-5x)',
    '--input-padding-icon': 'var(--spacing-scale-5x)',
    '--input-size': 'var(--input-medium)',
    '--input-small': '32px',
    '--input-medium': '40px',
    '--input-large': '48px',
    '--input-highlight': '56px',
    '--color': theme.palette.mode === 'dark' ? 'var(--color-dark)' : 'var(--color-light)',
    '--color-rgb': theme.palette.mode === 'dark' ? 'var(--color-dark-rgb)' : 'var(--color-light-rgb)',
    '--text-color': theme.palette.mode === 'dark' ? 'var(--color-dark)' : 'var(--color-light)',
    '--interactive': theme.palette.mode === 'dark' ? 'var(--interactive-dark)' : 'var(--interactive-light)',
    '--interactive-rgb': theme.palette.mode === 'dark' ? 'var(--interactive-dark-rgb)' : 'var(--interactive-light-rgb)',
    '--visited': theme.palette.mode === 'dark' ? 'var(--visited-dark)' : 'var(--visited-light)',
    '--hover': theme.palette.mode === 'dark' ? 'var(--hover-dark)' : 'var(--hover-light)',
    '--pressed': theme.palette.mode === 'dark' ? 'var(--pressed-dark)' : 'var(--pressed-light)',
    '--focus-color': theme.palette.mode === 'dark' ? 'var(--focus-color-dark)' : 'var(--focus-color-light)',
    '--focus': theme.palette.mode === 'dark' ? 'var(--focus-color-dark)' : 'var(--focus-color-light)',
    color: 'var(--color)',
    position: 'relative',
    'label + &': {
      marginTop: 'calc(var(--input-size) * 0.25 - var(--spacing-scale-half))'
    },
    [`& .${autocompleteClasses.input}`]: {
      color: 'var(--color-light)',
      display: 'block',
      fontSize: 'var(--font-size-scale-up-01)',
      fontWeight: 'var(--font-weight-medium)',
      height: 'calc(var(--input-size) * .9)',
      width: '100%',
      border: 'none!important',
      paddingBottom: '0',
      paddingLeft: 'var(--spacing-scale-base)',
      paddingRight: 'var(--spacing-scale-base)',
      paddingTop: '0',
    },
    [`& .input-container`]: {
      backgroundColor: theme.palette.mode === 'dark' ? 'var(--pure-0)' : 'var(--background-light)',
      borderColor: 'var(--border-color-alternative)',
      borderRadius: 'var(--surface-rounder-sm)',
      borderStyle: 'var(--border-style)',
      borderWidth: 'var(--border-width)',
      color: 'var(--color-light)',
      display: 'block',
      fontSize: 'var(--font-size-scale-up-01)',
      fontWeight: 'var(--font-weight-medium)',
      height: 'var(--input-size)',
      width: '100%',
      '&:hover': {
        backgroundColor: theme.palette.mode === 'dark' ? 'var(--pure-0)' : 'var(--gray-2)',
      }
    },
    [`&.${autocompleteClasses.error} input`]: {
      border: 'none'
    },
    [`&.${autocompleteClasses.error} .input-container`]: {
      borderColor: 'var(--danger)'
    },
    [`&.${autocompleteClasses.focused} input`]: {
      border: 'none',
      outline: 'none',
    },
    [`&.${autocompleteClasses.focused} .input-container`]: {
      borderColor: 'var(--focus) !important',
      boxShadow: '0 0 0 var(--surface-width-md) var(--focus)',
      outline: 'none',
    },
    [`&.${autocompleteClasses.expanded} svg`]: {
      transform: 'rotate(180deg)'
    },
    [`$ .${autocompleteClasses.option} .${autocompleteClasses.focused}`]: {
      color: 'var(--interactive-light)',
      backgroundColor: 'var(--pure-100)'
    }
})})

export default function GVAutocomplete(props) {

  const handleChange = (e, value) => {
    props.handleChange(value.id)
  }

  const value = props.options ? props.options.find(i => i.id == props.value) : null

  return (
    <React.Fragment>
      <GVBRAutocompleteLabel id={`${props.label}-select`} style={props.error ? { color: 'var(--danger)' } : {}}>{props.label}</GVBRAutocompleteLabel>
      <GVBRAutocomplete
        aria-labelledby={`${props.label}-select`}
        id={`${props.label}-select`}
        options={props.options}
        value={value ?? null}
        getOptionLabel={(option) => option.name}
        forcePopupIcon
        popupIcon={<ExpandMoreIcon />}
        onChange={handleChange}
        renderInput={(params) => (
          <div className='input-container' style={props.error ? { borderColor: 'var(--danger)' } : {}} ref={params.InputProps.ref}>
            <input style={{ zIndex: 2, position: 'relative', background: 'transparent' }} type="text" {...params.inputProps} />
            <InputAdornment position="end"
             sx={{ position: 'absolute', top: '50%', right: '1%', bottom: 0, zIndex: '1' }}>
                <ExpandMoreIcon sx={{ color: 'var(--interactive)' }} />
            </InputAdornment>
          </div>
        )}
      />
      { props.error && props.error === true && <GVFeedback danger>{props.errorMessage}</GVFeedback> }
    </React.Fragment>
  );
}