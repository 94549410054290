export const isInvalidFileType = (type) => {
  if (type === 'image/bmp' || type === 'image/gif' || type === 'image/jpeg' || type === 'image/png' || type === 'image/tiff' || type === 'application/msword' || type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || type === 'application/vnd.oasis.opendocument.presentation' || type === 'application/vnd.oasis.opendocument.spreadsheet' || type === 'application/vnd.oasis.opendocument.text' || type === 'application/pdf' || type === 'application/vnd.ms-powerpoint' || type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || type === 'application/rtf')
    return false
  else
    return true

  /*switch (type) {
    case 'image/bmp': // BMP
    case 'image/gif':  // GIf
    case 'image/jpeg': // JPG / JPEG
    case 'image/png': // PNG
    case 'image/tiff': // TIFF
    case 'application/msword': // DOC 
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // DOCX
    case 'application/vnd.oasis.opendocument.presentation': // ODP
    case 'application/vnd.oasis.opendocument.spreadsheet': // ODS
    case 'application/vnd.oasis.opendocument.text': // ODT
    case 'application/pdf': // PDF
    case 'application/vnd.ms-powerpoint': // PPT
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': // PPTX
    case 'application/rtf': // RTF
      return true;
    default:
      return false;
  }*/
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}