import { Link } from '@mui/material';
import React from 'react';
import './styles.css'
import LogoSmall from '../logo/logo-small';

function FormFooter() {
  return <div>
    <footer className='app-form-footer'>
      <div className='app-form-footer-content'>
        <div style={{ width: '170px' }}></div>
        <p style={{ color: 'rgb(62, 60, 60)', fontSize: 11 }}>CMTECH - Mexx 2DO - Atendimento inteligente ao cliente - Todos os direitos reservados.</p>
      </div>
    </footer>
    
  </div>;
}

export default FormFooter;