import React from 'react';
import { BrowserRouter, Switch, Route , Redirect} from "react-router-dom";

import Home from './pages/home'
import AttendanceSuccess from './pages/attendance-success'
import Page404  from './pages/page404/page404';
import Unavailable from './pages/unavailable'
import { LANGUAGES } from './_i18n/languages';
import attendanceErrorPep from './pages/attendance-error-pep';
import attendanceErrorBlacklist from './pages/attendance-error-blacklist';
import AttendanceErrorExists from './pages/attendance-error-exists';
import AttendanceErrorDocumentValidation from './pages/attendance-error-document-validation'
import AttendanceErrorSelfieValidation from './pages/attendance-error-selfie-validation'
import DisableTwoFactorVerification from './pages/disable-two-factor-verification';
import AttendanceErrorValidation from './pages/attendance-error-validation';
import Form from './pages/form';
import FormSendSuccess from './pages/form-send-success';
import FormSendErrorValidation from './pages/form-send-error-validation';
import FormSendErrorExists from './pages/form-send-error-exists';
import AlterAccountEmail from './pages/alter-account-email';
import AlterAccountEmailTest from './pages/alter-account-email-test';
import disable2FactorVerification from './pages/disable-2-factor-verification';
import AttendanceErrorPepNew from './pages/attendance-error-pep-2024';
import AttendanceErrorBlacklistNew from './pages/attendance-error-blacklist-2024';
import AttendanceErrorExistsNew from './pages/attendance-error-exists-2024';
import AttendanceErrorDocumentValidationNew from './pages/attendance-error-document-validation-2024'
import AttendanceErrorSelfieValidationNew from './pages/attendance-error-selfie-validation-2024'
import AttendanceErrorValidationNew from './pages/attendance-error-validation-2024';
import AttendanceSuccessNew from './pages/attendance-success-2024'
import { useVwContext } from './context/vw-context';

const MultiLanguageRoute = (props) => {
  const defaultLanguage = LANGUAGES.pt.urlLang;
  const hasLang = props.computedMatch.params.lang;
  const url = props.computedMatch.url;
  const is404Page = props.path;
  const isBasePathWithoutLang = props.path === '/';
  
  var currentUrlLang = window.location.pathname.split('/')[1];
  var currentLanguage = LANGUAGES[currentUrlLang];
  if(!currentLanguage) {
    currentLanguage = LANGUAGES[LANGUAGES.default];
  }
 

  if(isBasePathWithoutLang)  return  <Redirect to={`/${defaultLanguage}/login`} />
  if(!hasLang && !is404Page)

  var path = `${url}`;
  if(hasLang == url || '/'+hasLang == url){
     path = `/${currentLanguage.urlLang}${url}`;
  }
  return <Route language={currentLanguage.urlLang} {...props} />    
}

const VWMultiLanguageRoute = (props) => {
  const { handleToggleVwClass } = useVwContext()

  handleToggleVwClass(props.enableVW)

  return <MultiLanguageRoute {...props} />
}

export default function Routes() {
  return (
    <BrowserRouter forceRefresh={false}>
      <Switch>
        <VWMultiLanguageRoute enableVW={false} path="/" exact component={Home} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/" exact component={Home} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/alterar-email-na-conta" exact component={Home} />
        <VWMultiLanguageRoute enableVW={true} path="/:lang/alterar-email-na-conta-test" exact component={AlterAccountEmailTest} />
        <VWMultiLanguageRoute enableVW={true} path="/:lang/alterar-email" exact component={AlterAccountEmail} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/desativar-verificacao-em-duas-etapas" exact component={DisableTwoFactorVerification} />
        <VWMultiLanguageRoute enableVW={true} path="/:lang/desativar-2fa" exact component={disable2FactorVerification} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/form/:id" exact component={Form} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/unavailable" exact component={Unavailable} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/attendance-success" exact component={AttendanceSuccess} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/attendance-error-pep" exact component={attendanceErrorPep} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/attendance-error-validation" exact component={AttendanceErrorValidation} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/attendance-error-blacklist" exact component={attendanceErrorBlacklist} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/attendance-error-exists" exact component={AttendanceErrorExists} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/attendance-error-document-validation" exact component={AttendanceErrorDocumentValidation} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/attendance-error-selfie-validation" exact component={AttendanceErrorSelfieValidation} />
        <VWMultiLanguageRoute enableVW={true} path="/:lang/attendance-success-2024" exact component={AttendanceSuccessNew} />
        <VWMultiLanguageRoute enableVW={true} path="/:lang/attendance-error-pep-2024" exact component={AttendanceErrorPepNew} />
        <VWMultiLanguageRoute enableVW={true} path="/:lang/attendance-error-validation-2024" exact component={AttendanceErrorValidationNew} />
        <VWMultiLanguageRoute enableVW={true} path="/:lang/attendance-error-blacklist-2024" exact component={AttendanceErrorBlacklistNew} />
        <VWMultiLanguageRoute enableVW={true} path="/:lang/attendance-error-exists-2024" exact component={AttendanceErrorExistsNew} />
        <VWMultiLanguageRoute enableVW={true} path="/:lang/attendance-error-document-validation-2024" exact component={AttendanceErrorDocumentValidationNew} />
        <VWMultiLanguageRoute enableVW={true} path="/:lang/attendance-error-selfie-validation-2024" exact component={AttendanceErrorSelfieValidationNew} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/form-send-success" exact component={FormSendSuccess} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/form-send-error-validation" exact component={FormSendErrorValidation} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/form-send-error-exists" exact component={FormSendErrorExists} />
        <VWMultiLanguageRoute enableVW={false} path="*" component={Page404}/>
      </Switch>
    </BrowserRouter>
  );
}