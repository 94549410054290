import React from 'react';
import IconButton from '@mui/material/IconButton';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { useVwContext } from '../../context/vw-context';
import GVIconButton from '../icon-button';

const VwButton = (props) => {
  const { toggleVwClass, isVwActive } = useVwContext();
  
  return (
    <GVIconButton onClick={toggleVwClass} disabled={isVwActive}>
      <AccessibilityIcon style={props.btnStyle && props.btnStyle} />
    </GVIconButton>
  );
};

export default VwButton;